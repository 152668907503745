export default {
  language: 'اللغة',
  dashboard: 'لوحة التحكم',
  analytics: 'تحليلات البيانات',
  morrow: 'Morrow AI',
  cardTitleDah: 'مجموع الزيارات اليومي',
  cardTitleDah2: 'إجمالي المبيعات اليوم',
  Content: 'المحتوى',
  date: 'التاريخ',
  BrandName: 'اسم العلامة التجارية',
  jobs: 'الوظائف',
  UserID: 'رقم المستخدم',
  EmployeeName: 'اسم الموظف',
  editBlog: 'تعديل المدونة',
  AvgTimePerCup: 'متوسط ​​الوقت لكل كوب',
  AtEspresso: 'وقت الانتظار',
  blog: 'مدونة',
  CoffeeSection: 'قسم القهوة',
  AtCashier: 'عند الكاشير',
  IsWearingGloves: 'هل نظارة؟',
  EspressoWaste: 'مصاريب الاكسبرسو',
  NumberOfCups: 'عدد الكوبات',
  AtBar: 'وقت الانتظار',
  delete: 'حذف',
  cardTitleDah3: 'الوضع الحالي',
  DoyouwanttodeletetheBlog: 'هل تريد حذف المدونة؟',
  cardTitleDah4: 'متوسط ​​الوقت المستغرق للانتظار (ثواني)',
  target: 'الهدف',
  today: 'اليوم',
  upload: 'تحميل',
  blogs: 'المدونات',
  id: 'الرقم',
  Project: 'المشروع',
  Task: 'المهمة',
  slugPlaceholder: 'اكتب رابط المدونة',
  AssignedTo: 'تخصيص ل',
  DueDate: 'تاريخ الانتهاء',
  slug: 'الرابط',
  ClientName: 'اسم العميل',
  TimeInsideBar: 'وقت الانتظار',
  NumberOfCups: 'عدد الكوبات',
  ManyCupCoffee: 'عدد كوبات القهوة',
  WearingGloves: 'استخدام قفازات',
  SearchByName: 'بحث بالاسم',
  AvgTimeDoingOrder: 'متوسط ​​الوقت للانتظار للطلب',
  Picture: 'الصورة',
  emplyeePerformance: 'أداء الموظف',
  Volume: 'الحجم',
  SearchByClientName: 'بحث باسم العميل',
  inventory: 'المخزون',
  image: 'صورة',
  emplyee: 'موظف',
  ManyCopCoffee: 'عدد أكواب القهوة',
  Pairs: 'مقاطعة',
  SRNo: 'رقم التسلسل',
  Action: 'عملية',
  Currency: 'العملة',
  High: 'أعلى 24 ساعة',
  CupsSoldVsWasted: 'الكوب المباع مقابل الكوب المهدر',
  cupsWasted: 'الكوب المهدر',
  Low: 'أقل 24 ساعة',
  cupsSold: 'الكوب المباع',
  MarketVolume: 'حجم السوق',
  addBlog: 'اضافة مدونة',
  description: 'الوصف',
  NumberOfClients: 'عدد العملاء',
  title: 'العنوان',
  IsSuperUser: 'هل هو مشرف؟',
  Clients: 'العملاء',
  TimeRange: 'نطاق الوقت',
  Time: 'الوقت',
  Timeseconds: 'الوقت (ثواني)',
  WaitingTime: 'وقت الانتظار',
  WaitingTimes: 'وقت الانتظار',
  NumberOfEspressoCups: 'عدد كوبات الاسبريسو',
  CustomerEmail: 'البريد الالكتروني',
  Location: 'الموقع',
  Last: 'السابق',
  NumberOfCoffeeCups: 'عدد أكواب القهوة',
  Next: 'التالي',
  man: 'الرجال',
  woman: 'النساء',
  kid: 'الاطفال',
  LastDays: 'الايام السابقة',
  SalesTransaction: 'معاملات البيع',
  TransactionCount: 'عدد المعاملات',
  action: 'عمل',
  Week: 'الاسبوع',
  features: 'الميزات',
  subfeatures: 'الميزات الفرعية',
  VisitCountvsSalesConversionWeeklyTrend:
    'زيارة العدد مقابل تحويل المبيعات الاتجاه الأسبوعي',
  Weekday: 'ايام الاسبوع',
  Visitors: 'الزوار',
  plans: 'الخطط',
  Months: 'شهور',
  Sales: 'المبيعات',
  ThisWeek: 'هذا الاسبوع',
  Changes: 'التغييرات',
  Saturday: 'السبت',
  VisitCount: 'عدد الزيارات',
  VisitCountvsSalesConversionMonthlyTrend:
    'زيارة العدد مقابل تحويل المبيعات الاتجاه الشهري',
  SalesConversion: 'تحويل المبيعات',
  TopProduct_Type: 'أعلى 5 مبيعات حسب فئة المنتج',
  BestPerformDay: 'أفضل يوم أداء',
  LastWeek: 'الاسبوع السابق',
  SalesByProductDetails: 'المبيعات حسب تفاصيل المنتج',
  WeekendPublicHolidays: 'عطلة نهاية الأسبوع والعطلات الرسمية',
  VisitorCount: 'عدد الزيارات',
  TotalSalesbyProductType: 'إجمالي المبيعات حسب نوع المنتج',
  VisitorCountProfile: 'الملف الشخصي لعدد الزوار',
  Storemanageristyping: 'مدير المتجر يكتب...',
  ChatwithStore: 'تكلم مع مدير المتجر',
  TotalSales: 'المبيعات الكليه',
  VisitorCount: 'عدد الزيارات',
  SalesVolume: 'مقياس المبيعات',
  AvarageUse: 'متوسط الاستخدام',
  week: 'الاسبوع',
  Typeyourmessagehere: 'اكتب رسالتك هنا...',
  DailyTrend: 'تحليلات الزيارات اليومية',
  TopProducts: 'المنتجات الاعلى',
  asknexta: 'مدير المتجر',
  Editor: 'المحرر ',
  upgradePlan: 'ترقية الخطة',
  upgradeYourPlan: 'ترقية الخطة الخاصة بك',
  SalesRevenue: 'المبيعات',
  month: 'الشهر',
  storeHello: 'مرحبا انا مدير متجرك كيف يمكنني مساعدتك؟',
  TotalSalesGraph: 'مجموع المبيعات',
  Hours: 'الساعات',
  Days: 'الايام',
  active: 'نشط',
  inactive: 'غير نشط',
  notification: 'الاشعارات',
  Dateofaccession: 'تاريخ الدخول',
  JoinDate: 'تاريخ الانضمام',
  ExpiryDate: 'تاريخ الانتهاء',
  Email: 'البريد الالكتروني',
  phoneNumber: 'رقم الجوال',
  TotalCapacity: 'السعة الإجمالية',
  branches: 'الفروع',
  Discount: 'خصم',
  actions: 'الاجراءات',
  SubscriptionPrice: 'سعر الاشتراك',
  addBranch: 'اضافة فرع',
  branche: 'الفرع',
  Password: 'كلمة المرور',
  site: 'الموقع',
  DoyouwanttodeletetheCustomer: 'هل تريد حذف العميل؟',
  Doyouwanttodeletethebranch: 'هل تريد حذف الفرع؟',
  areyousure: 'هل انت متاكد؟',
  AccountStatus: 'حالة الحساب',
  Status: 'الحالة',
  Priority: 'الاولوية',
  addCustomer: 'اضافة عميل',
  no: 'لا',
  yes: 'نعم',
  newOrder: 'طلب جديد',
  Number: 'الرقم',
  featureName: 'اسم الميزة',
  customers: 'العملاء',
  Client: 'العميل',
  price: 'السعر',
  subscriptionDate: 'تاريخ الاشتراك',
  name: 'الاسم',
  CustomerNumber: 'رقم العميل',
  addSubfeature: 'اضافة ميزة فرعية',
  subfeatureName: 'اسم الميزة الفرعية',
  addplan: 'اضافة خطة',
  description: 'الوصف',
  planName: 'اسم الخطة',
  subfeature: 'الميزة الفرعية',
  addFeature: 'اضافة ميزة',
  plan: 'الخطة',
  submit: 'ارسال',
  feature: 'ميزة',
  cancel: 'الغاء',
  edit: 'تعديل',
  Name: 'الاسم',
  LevelUse: 'مستوي الاستخدام',
  ActualQty: 'الكمية الفعلية',
  PredectionQty: 'الكمية النهائية',
  AvarageUse: 'متوسط ​​الاستخدام',
  current: 'الحالي',
  Heatmap: 'مخطط',
  noDataMessage: 'لا توجد بيانات للمخطط',
  VisitAnalysis: 'تحليلات الزيارات',
  SalesAnalysis: 'تحليلات المبيعات',
  MarketingPromotions: 'التسويق والترويج',
  InventoryManagement: 'إدارة المخزون',
  CustomerName: 'اسم العميل',
  Total: 'مجموع',
  subTotal: 'المجموع الفرعي',
  Visits: 'الزيارات',
  TotalFemaleandMaleandKids: 'مجموع:(النساء والرجال والاطفال)',
  DailyTotalVisits: 'مجموع الزيارات اليومية',
  AverageDailyVisits: 'متوسط ​​الزيارات اليومية',
  AverageHourlyVisits: 'متوسط ​​الزيارات الساعية',
  promotinAnalytics: 'تحليلات البيانات',
  cardTitleDashThree: 'الزيارات اليومية حسب الجنس',
  cardTitleDashThree2: 'الزيارات اليومية ',
  sectionDashTwo2: 'مجموع الزيارات حسب الموقع',
  gender: 'الجنس',
  location: 'الموقع',
  titleTable: 'ملخص زيارات جميع المواقع الأسبوعي',
  sectionOneAnalytics: 'أكثر ساعات اليوم ازدحاما',
  sectionTwoAnalytics: 'التوزيع بين الجنسين حسب الفترة الزمنية',
  sectionThreeAnalytics: 'آخر 12 شهر',
  profile: 'الملف الشخصي',
  logout: 'تسجيل الخروج',
  ProductCategoryDailySales: 'مجموع المبيعات اليومية حسب الفئة',
  changeUser: 'تغيير اسم المستخدم',
  userName: 'اسم المستخدم',
  updateUser: 'تعديل اسم المستخدم',
};
