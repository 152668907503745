export const queryKeys = {
  genderList: 'genderList',
  getGenderVisits: 'getGenderVisits',
  getJobs: 'getJobs',
  getGenderTrends: 'getGenderTrends',
  getManyCupCoffee: 'getManyCupCoffee',
  getBusiestHour: 'getBusiestHour',
  getGenderVisitsCards: 'getGenderVisitsCards',
  getLastMonthVisits: 'getLastMonthVisits',
  getBlogs: 'getBlogs',
  getEmployee: 'getEmployee',
  getVisitorsVolumeGraph: 'getVisitorsVolumeGraph',
  getWaitingTimes: 'getWaitingTimes',
  getCupsSoldWasted: 'getCupsSoldWasted',
  getInventory: 'getInventory',
  getClientTrafficByTime: 'getClientTrafficByTime',
  getGenderDistribution: 'getGenderDistribution',
  notificationList: 'notificationList',
  getHeatmap: 'getHeatmap',
  getUsers: 'getUsers',
  getFeatures: 'getFeatures',
  getSubFeatures: 'getSubFeatures',
  getBranches: 'getBranches',
  getAllUsers: 'getAllUsers',
  getUsersById: 'getUsersById',
};
