import React, { useState } from 'react';
import {
  Avatar,
  Badge,
  Button,
  Divider,
  Dropdown,
  Flex,
  Layout,
  Select,
  Space,
} from 'antd';
import {  FaUserClock,FaUserTie} from 'react-icons/fa';
import { RiBloggerFill } from "react-icons/ri";
import { useNavigate } from 'react-router-dom';
import { DownOutlined, BranchesOutlined } from '@ant-design/icons';
import { IoAnalyticsOutline, IoChatbubbleEllipsesSharp } from 'react-icons/io5';
import { MdLightMode, MdDarkMode, MdLanguage } from 'react-icons/md';
import { RiFullscreenLine } from 'react-icons/ri';
import { BsThreeDotsVertical } from 'react-icons/bs';
import logo from '../assets/images/mainLogo.png';
import logoLight from '../assets/images/logo-removebg-preview.png';
import logo2 from '../assets/images/logo-removebg-preview(1).png';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  LogoutOutlined,
  UserOutlined,
} from '@ant-design/icons';

import { useDispatch, useSelector } from 'react-redux';
import {
  clearUser,
  setActiveBranche,
  setActiveBrancheId,
  setActiveBrancheName,
  setCurrentLang,
  setDarkMode,
  setImageActiveBranche,
} from '../Store/user/UserSlice';
import { GrDashboard } from 'react-icons/gr';
import { FaMoneyCheckDollar } from 'react-icons/fa6';
import { FaBoxes, FaChartLine, FaUsers } from 'react-icons/fa';
import { IoMdNotificationsOutline } from 'react-icons/io';
import { salonSVG } from '../assets/svgs/svgs';
import DrawerLinks from './DrawerLinks';
import { CiMenuFries } from "react-icons/ci";

const { Header } = Layout;
const HeaderApp = ({ t, admin, collapsed, setCollapsed }) => {
  const { darkMode, branchesList, activeBrancheId,Features } = useSelector(
    (state) => state.user
  );
  const [openDrawer, setOpenDrawer] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const chanageBranch = (value, option) => {
    // setActiveBranchesId(id)
    // console.log("optionoptionoptionoption",option);
    dispatch(setActiveBrancheId(value));
    dispatch(setActiveBrancheName(option?.option?.SiteID));
    dispatch(setImageActiveBranche(option?.option?.ImagePath));
    dispatch(setActiveBranche(option?.option));
  };
  // console.log(getBrancheActive(),"getBrancheActive");

  const handleDarkMode = (dark) => {
    dispatch(setDarkMode(dark));
  };
  const items = [
    {
      key: 'en',
      label: 'English',
    },
    {
      key: 'ar',
      label: 'العربية',
    },
  ];
  const itemRes = [
    {
      key: '1',
      label: <span> {t.dashboard} </span>,
      onClick: () => navigate('/'),
      icon: <GrDashboard className="navbar-icon" />,
    },
    {
      key: '2',
      label: <span> {t.VisitAnalysis} </span>,
      onClick: () => navigate('/analytics'),
      icon: <IoAnalyticsOutline className="navbar-icon" />,
    },
    {
      key: '3',
      label: <span> {t.SalesAnalysis} </span>,
      onClick: () => navigate('/SalesAnalysis'),
      icon: <FaMoneyCheckDollar className="navbar-icon" />,
    },
    // {
    //   key: '4',
    //   label: <span> {t.MarketingPromotions} </span>,
    //   onClick: () => navigate('/MarketingPromotions'),
    //   icon: <FaChartLine className="navbar-icon" />,
    // },
    Features?.includes('Inventory') && {
      key: '5',
      label: <span> {t.InventoryManagement} </span>,
      onClick: () => navigate('/InventoryManagement'),
      icon: <FaBoxes className="navbar-icon" />,
    },
      Features?.includes('Ask Store') && {        
        key: '6',
        label: <span> {t.asknexta} </span>,
        onClick: () => navigate('/asknexta'),
        icon: <IoChatbubbleEllipsesSharp className="navbar-icon" />,
      },
      Features?.includes('Emplyee') && {        
        key: '7',
        label: <span> {t.emplyee} </span>,
        onClick: () => navigate('/emplyee-performance'),
        icon: <FaUserClock className="navbar-icon" />,
      },
  ];
  const itemResAdmin = [
    {
      key: '1',
      label: <span> {t.dashboard} </span>,
      onClick: () => navigate('/admin'),
      icon: <GrDashboard className="navbar-icon" />,
    },
    {
      key: '2',
      label: <span> {t.jobs} </span>,
      onClick: () => navigate('/admin/jobs'),
      icon: <FaUserTie className="navbar-icon" />,
    },
    {
      key: '3',
      label: <span> {t.blogs} </span>,
      onClick: () => navigate('/admin/blogs'),
      icon: <RiBloggerFill className="navbar-icon" />,
    },
    {
      key: '4',
      label: <span> {t.branches} </span>,
      onClick: () => navigate('/admin/branches'),
      icon: <BranchesOutlined className="navbar-icon" />,
    },
    {
      key: '5',
      label: <span> {t.customers} </span>,
      onClick: () => navigate('/admin/customers'),
      icon: <FaUsers className="navbar-icon" />,
    },
  ];
  const itemsNotification = [
    {
      type: 'group',
      label: t.notification,
      children: [1, 2, 3, 4, 5, 6, 7, 8]?.map((item) => ({
        key: item?.key,
        label: (
          <>
            <div className="notification-card">
              <div className="notification-container">
                <div className="img-notification">
                  <img src={logoLight} alt="" />
                </div>
                <div className="content-notification">
                  <div className="info-notification">
                    <h3 className="name-notification">Ahmed</h3>
                    <div className="date-notification">
                      <time className="time-notification">5m ago</time>
                      <Badge color="rgb(45, 183, 245)" />
                    </div>
                  </div>
                  <h3 className="title-notification">{t.newOrder}</h3>
                </div>
              </div>
            </div>
          </>
        ),
      })),
    },
    // {
    //   label: "مشاهدة الكل",
    //   key: "30",
    //   className: "view-all",
    //   onClick: () => {
    //     console.log("click");
    //   }
    // },
  ];
  const chanageLang = ({ key }) => {
    dispatch(setCurrentLang(key));
    document.getElementsByTagName('html')[0].setAttribute('lang', key);
  };
  const handleFullScreen = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else {
      document.documentElement.requestFullscreen();
    }
  };
  return (
    <>
      <Header
        style={{
          position: 'sticky',
          top: 0,
          zIndex: 999,
          width: '100%',
          padding: '0 24px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          backgroundColor: darkMode ? '#38414a' : '#fff',
        }}
      >
        <Space align="center">
          <div className="site-layout-responsive">
            <Button
              type="text"
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={() => setCollapsed(!collapsed)}
              style={{
                fontSize: '16px',
              }}
            />
          </div>
          <div className="dropdown-link-responsive">
            <Flex align="center" gap="15px">
              <div
                className="img"
                onClick={() => navigate(admin ? '/admin' : '/')}
              >
                <img
                  src={darkMode ? logo : logoLight}
                  alt="logo"
                  className='logo-one'
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    objectPosition: 'center',
                    cursor: 'pointer',
                    border: 'none !important',
                  }}
                />
                <img src={logo2} alt="logo"
                  className='logo-two'
                  style={{
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    objectPosition: 'center',
                    cursor: 'pointer',
                  }}
                />
              </div>
              <Dropdown
                arrow={{ pointAtCenter: true }}
                trigger={['click']}
                placement="bottom"
                menu={{
                  items: admin ? itemResAdmin : itemRes,
                }}
              >
                <Button type="text"
                icon={<BsThreeDotsVertical size={30} />}
                />
              </Dropdown>
              {/* <Button type="text" icon={<CiMenuFries size={30} />}
                onClick={() => setOpenDrawer(true)}
              /> */}
            </Flex>
          </div>
          {/* {!admin && <ModalSearch t={t} darkMode={darkMode}  />} */}
          {/* <Input.Search
                className={`search-input ${
                  darkMode ? 'dark-search' : 'light-search'
                }`}
                placeholder="search..."
                allowClear
                onSearch={(value) => console.log(value)}
                enterButton={false}
                width={200}
                prefix={
                  <IoSearchOutline style={{ color: '#505d69' }} size={20} />
                }
              /> */}
        </Space>
        <Space align="center" className="own-header-space">
          {admin && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Dropdown
                rootClassName="dropdown-style-own"
                arrow={{ pointAtCenter: true }}
                placement="bottom"
                menu={{
                  items: itemsNotification,
                  onClick: (e) => {
                    console.log(e);
                  },
                }}
                trigger={['click']}
                // placement="bottom"
              >
                <Flex className="own-header-space" align="center" gap="5px">
                  <Badge count={4} size="small" className="badge-style">
                    <Avatar
                      size={38 * 0.8}
                      style={{
                        backgroundColor: 'transparent',
                        color: darkMode ? '#fff' : '#002554',
                      }}
                      icon={<IoMdNotificationsOutline size={25} />}
                    />
                  </Badge>
                </Flex>
              </Dropdown>
            </div>
          )}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div onClick={handleFullScreen} className="fullscreen">
              <RiFullscreenLine
                size={25}
                color={darkMode ? '#fff' : '#505d69'}
              />
            </div>
          </div>
          {/* <div className="language"> */}
          <Dropdown
            arrow={{ pointAtCenter: true }}
            // trigger={['click']}
            trigger={['hover']}
            menu={{ items: items, onClick: chanageLang }}
          >
            <Space
              align="center"
              style={{
                fontSize: '16px',
                fontWeight: '600',
                cursor: 'pointer',
              }}
              // className="lang-dropdown"
            >
              {/* <span className="lang-text">{t.language}</span> */}
              <MdLanguage size={20} />
            </Space>
          </Dropdown>
          {/* </div> */}
          <div
            className="dark"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {darkMode ? (
              <div
                style={{
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                onClick={() => handleDarkMode(false)}
              >
                <MdDarkMode size={28} color="#fff" />
              </div>
            ) : (
              <div
                style={{
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                onClick={() => handleDarkMode(true)}
              >
                <MdLightMode size={28} style={{ color: '#002554' }} />
              </div>
            )}
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              // gap: '5px',
            }}
          >
            {branchesList?.length > 0 && (
              <>
                <Select
                  onChange={chanageBranch}
                  popupMatchSelectWidth={false}
                  defaultValue={activeBrancheId}
                  // style={{
                  //   maxWidth: 150,
                  // }}
                  size="small"
                  variant="filled"
                  options={branchesList?.map((branch) => {
                    return {
                      label: (
                        <Flex align="center" gap="5px">
                          {salonSVG}
                          <p
                            style={{
                              // color: darkMode ? '#fff' : '#002554',
                              fontSize: '14px',
                            }}
                          >
                            {branch?.Location}
                          </p>
                        </Flex>
                      ),
                      key: branch?._id,
                      value: branch?._id,
                      icon: salonSVG,
                      option: branch,
                    };
                  })}
                  showSearch={false}
                  // className="branch-select"
                  loading={false}
                  // placeholder={t.branch}
                  // size="small"
                  // value={branchID}
                  // bordered={false}
                  popupClassName="branch-select-popup"
                  // suffixIcon={
                  //   <BranchesOutlined
                  //     style={{
                  //       fontSize: '20px',
                  //       color: darkMode ? '#fff' : '#002554',
                  //       cursor: 'pointer',
                  //     }}
                  //   />
                  // }
                />
                <Divider type="vertical" />
              </>
            )}
            <Dropdown
              menu={{
                items: [
                  {
                    key: '1',
                    label: <span to="/profile">{t.profile}</span>,
                    icon: <UserOutlined />,
                    onClick: () => navigate('/profile'),
                  },
                  {
                    type: 'divider',
                  },
                  {
                    key: '3',
                    label: t.logout,
                    icon: <LogoutOutlined />,
                    danger: true,
                  },
                ],
                onClick: (e) => {
                  console.log(e);
                  if (e?.key === '3') {
                    navigate(admin ? '/admin/login' : '/login');
                    dispatch(clearUser());
                  }
                },
              }}
              trigger={['click']}
              arrow
              style={{
                cursor: 'pointer',
                color: '#002554',
              }}
            >
              <Space
                style={{
                  cursor: 'pointer',
                  color: darkMode ? '#fff' : '#002554',
                  height: '15px',
                }}
              >
                <Badge className="badge-style-own">
                  <Avatar
                    style={{
                      backgroundColor: 'transparent',
                      color: darkMode ? '#fff' : '#002554',
                    }}
                    size={38}
                    icon={
                      <UserOutlined
                        size={30}
                        color={darkMode ? '#fff' : '#002554'}

                      />
                    }
                  />
                </Badge>
                <span className="admin-dropdown">Admin</span>
                <DownOutlined className="admin-dropdown" style={{ fontSize: '14px', color: '#636e75' }} />
              </Space>
            </Dropdown>
          </div>
        </Space>
      </Header>
      <DrawerLinks t={t} open={openDrawer} setOpen={setOpenDrawer} admin={admin} />
    </>
  );
};

export default HeaderApp;
